import React from "react";
// import car5 from "../assets/images/Charity Cup - Copy.jpg";
import charity1 from "../assets/images/charity2.jpg";
import charity2 from "../assets/images/charity6.jpg";
import charity3 from "../assets/images/charity3.jpg";
import charity4 from "../assets/images/charity7.jpg";
import charity5 from "../assets/images/Charity1.jpg";
import charity6 from "../assets/images/charity4.jpg";
import charity9 from "../assets/images/charity9.jpg";
import "../styles/CharityCup.css";
import { Container, Row, Col } from "react-bootstrap";

const Volunteership = () => {
  return (
    <>
      <section style={{ marginBottom: "0%" }}>
        {/* <div className="image-container">
          <img
            src={car5}
            alt=""
            style={{
              objectFit: "cover",
              width: "100%",
              height: "400px",
              borderRadius: "0",
            }}
          />
        </div> */}
        <div>
          <h4 className="head1">
            <br />
            CHARITY CUP
          </h4>
          <div className="containerofwhat">
            <div className="textofwhat" style={{}}></div>
          </div>
          <br />
        </div>
      </section>
      <section>
        <Container>
          <Row className="equal-height-cols">
            <Col lg={8} md={8} sm={12}>
              <div className="leftsidetextcharity">
                The Charity Cup is an annual football tournament organized by
                the WeCare Social Welfare Society. The Charity Cup is a
                fundraising competition where elite football teams compete for a
                cause. Every penny earned through the event went to the
                development of kids in underprivileged communities and other
                endeavors to bring positive change. The Charity Cup stands as
                one of the most esteemed sports competitions in Bhopal, making a
                significant impact through the power of sport.
              </div>
            </Col>
            <Col>
              <img src={charity9} alt="" className="charity-image1" />
            </Col>
          </Row>
          <Row className="photorow1">
            <Col>
              <img src={charity2} alt="" className="charity-imagebet" />
            </Col>
            <Col>
              <img src={charity1} alt="" className="charity-imagebet" />
            </Col>
            <Col>
              <img src={charity4} alt="" className="charity-imagebet" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="midtext">
                SEASON 1 -- <br />
                The inaugural edition of Charity Cup was a blast with 32 teams
                participating for a cause. The event attracted an audience of
                1.5 Lakh spectators. In the very first edition itself, the
                Charity Cup became one of the most prominent football
                competitions in Bhopal. <br /> <br />
                SEASON 2 -- <br />
                The season 2 of the Charity Cup was an even greater success.
                With 20 teams participating in the tournament, we reached an
                audience of 2 lakhs with a daily footing of 4,000 individuals.
                Charity Cup became a household name among the football circle of
                Bhopal.
              </p>
            </Col>
          </Row>
          <Row className="photorow2">
            <Col>
              <img src={charity5} alt="" className="charity-imagebet" />
            </Col>
            <Col>
              <img src={charity3} alt="" className="charity-imagebet" />
            </Col>
            <Col>
              <img src={charity6} alt="" className="charity-imagebet" />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <img src={charity3} alt="" className="charity-image2" />
            </Col>
            <Col>
              <div className="rightsidetextcharity">
                SEASON 3 --
                <br />
                Charity Cup 3.0 was organized in a prime location in Bhopal. The
                event was an improvement from previous editions in every way.
                Elite football teams participated in the highly intensified
                competition. With the footing of 6,000 and 4 Lakhs viewers the
                words of the event echoed far beyond the football sphere and
                reached a wider audience
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Volunteership;
