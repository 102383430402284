import React, { useState } from "react";
import car5 from "../assets/images/car5.jpg";
import "../styles/Volunteership.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Volunteership = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <>
      <section>
        <div className="image-container">
          <img
            src={car5}
            alt=""
            style={{
              objectFit: "cover",
              width: "100%",
              height: "400px",
              borderRadius: "0",
              filter: "blur(3px)",
            }}
          />
        </div>
        <div>
          <h4 className="head1">
            <br />
            VOLUNTEERSHIP PROGRAM
          </h4>
          <div className="containerofwhat">
            <div className="textofwhat" style={{}}></div>
          </div>
          <br />
        </div>
      </section>
      <section style={{ marginBottom: "5%" }}>
        <Container>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <div className="leftsidetext">
                Joining as a volunteer at <b>We Care Social Welfare Society </b> 
                offers an incredible opportunity to make a meaningful impact in
                the lives of others. Whether you're passionate about education,
                healthcare, environmental sustainability, or community
                development, We Care provides a platform to channel your
                skills and dedication toward helping those in need. By becoming
                a volunteer, you'll be part of a team committed to uplifting
                underprivileged communities, driving social change, and creating
                sustainable solutions. Your contributions—whether in organizing
                events, spreading awareness, or offering hands-on support—will
                help strengthen grassroots efforts and empower communities to
                thrive. Volunteering with We Care is not only a chance to
                give back but also to grow personally, develop new skills, and
                be part of a compassionate, driven community focused on making a
                difference. Together, we can create a brighter, more equitable
                future — one volunteer at a time.
              </div>
            </Col>
            <Col>
              <div className="rightsidetext1">
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-3">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Volunteership;
