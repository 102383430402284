import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import car5 from "../assets/images/car5.jpg";
import "../styles/Sponsorship.css";

const Sponsorship = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const formRef = useRef(null); // Create a ref for the form

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_909c4z2", // Your service ID
        "template_o0h4emj", // Your template ID
        formRef.current, // Form reference
        "mrAY7SQBW-Bdifzop" // Your public key
      )
      .then(
        (result) => {
          console.log("Email sent successfully", result.text);
          window.alert("Form submitted successfully!");
        },
        (error) => {
          console.error("Failed to send email", error.text);
          window.alert("Failed to send email, please try again later.");
        }
      );
  };

  return (
    <>
      <section>
        <div className="image-container">
          <img
            src={car5}
            alt=""
            style={{
              objectFit: "cover",
              width: "100%",
              height: "400px",
              borderRadius: "0",
              filter: "blur(3px)",
            }}
          />
        </div>
        <div>
          <h4 className="head1">
            <br />
            SPONSORSHIP PROGRAM
          </h4>
        </div>
      </section>

      <section style={{ marginBottom: "5%" }}>
        <Container>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <div className="leftsidetext">
                Sponsors have the unique opportunity to be a part of the change
                through the Charity Cup, an initiative that blends sportsmanship
                with social responsibility. By funding this event, sponsors
                directly contribute to empowering underprivileged communities.
                The Charity Cup, with both male and female teams, fosters gender
                equality, inclusivity, and teamwork, making a lasting impact on
                the participants’ lives. Your support not only fuels the event
                but also amplifies our ability to reach more communities. From
                helping provide essential resources to ensuring smooth
                organization, sponsorship plays a crucial role in driving this
                initiative forward. By joining hands with us, sponsors can be a
                catalyst for change.
              </div>
            </Col>
            <Col>
              <div className="rightsidetext">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-3">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sponsorship;
